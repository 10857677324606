import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { useMediaQuery, useTheme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import CBenefitTile from 'storybook-component-module/src/components/tiles/CBenefitTile'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import ICDiscountBadgeIcon from 'storybook-component-module/src/components/tiles/ICDiscountBadgeIcon'
import { additionalBenefits } from 'spypoint/src/user-module/club-membership/core/insidersClubBenefits.selectors'

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.contrastText,
  },
  discountPlacement: {
    marginTop: '-65px !important',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-45px !important',
    },
  },
  additionalBenefitsPlacement: {
    marginTop: '12px !important',
  },
  additionalBenefits: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  description: {
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '0rem 0.75rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
      padding: '0rem 0.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.625rem',
    },
  },
  icon: {
    width: '40%',
    height: '40%',
    marginBottom: '1rem',
  },
  caption: {
    fontSize: '0.625rem',
  },
}))

const MembershipFooterDesktop = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const smBreakpoints = useMediaQuery(theme.breakpoints.only('sm'))
  const mdBreakpoints = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container maxWidth={false} className={classes.container}>
      <Container maxWidth={smBreakpoints ? 'sm' : 'md'} disableGutters>
        <Grid container spacing={mdBreakpoints ? 4 : 6}>
          <ICDiscountBadgeIcon
            discount={20}
            message="app:insiders_club.footer.discount"
            className={classes.discountPlacement}
          />

          <Grid item sm className={classes.additionalBenefits}>
            { additionalBenefits.map(benefit => (
              <CBenefitTile
                key={benefit.id}
                icon={benefit.icon}
                description={t(benefit.description)}
                descriptionClass={classes.description}
                showDivider={false}
                className={classes.additionalBenefitsPlacement}
                iconClass={classes.icon}
              />
            )) }
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" py={mdBreakpoints ? 4 : 6}>
          <CTypography variant="caption" className={mdBreakpoints ? classes.caption : null}>
            { t('app:insiders_club.footer.caption') }
          </CTypography>
        </Box>
      </Container>
    </Container>
  )
}

export default MembershipFooterDesktop
