import { Dialog } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useLanguage from 'shared-module/components/language/useLanguage'

const SPYPOINT_URL = 'https://www.spypoint.com/'
const generateURL = lang => {
  const PATH = lang === 'en' ? '/app/terms-use' : '/app/conditions-utilisation'
  return `${SPYPOINT_URL}${lang}${PATH}`
}

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(6),
    zIndex: 999,
  },
  termLink: {
    marginLeft: 4,
    display: 'inline',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontFamily: theme.typography?.fontFamilyHind,
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  termsPrefix: {
    display: 'inline',
    fontFamily: theme.typography?.fontFamilyHind,
  },
  iframe: {
    overflow: 'auto',
    height: '100%',
    width: '100%',
    '-webkit-overflow-scrolling': 'touch',
  },
  termsAndConditions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
  },
}))

const TermsAndConditionsLine = ({ newCheckout = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const language = useLanguage()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className={classes.termsAndConditions}>
        <span className={classes.termsPrefix}>
          { newCheckout
            ? t('billing.terms_conditions_pay_now')
            : t('billing.terms_conditions_place_order') }
        </span>
        <span className={classes.termLink} id="terms-external-link" onClick={() => setIsOpen(true)}>
          { t('billing.terms_conditions').toLocaleLowerCase() }
        </span>
      </div>

      <Dialog
        fullScreen
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Fab
          aria-label="Close"
          id="close-iframe"
          onClick={() => setIsOpen(false)}
          className={classes.fab}
        >
          <CloseIcon />
        </Fab>
        <div className={classes.iframe}>
          <iframe
            src={generateURL(language)}
            frameBorder="0"
            height="100%"
            width="100%"
            title="terms-conditions-frame"
          />
        </div>
      </Dialog>
    </>
  )
}

export default TermsAndConditionsLine
