import React, { ReactNode } from 'react'

import Grid from '@mui/material/Grid'
import MuiBox from '@mui/material/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'

import CTitle from '../typographies/CTitle'
import CDivider from '../dividers/CDivider'
import CTypography from '../typographies/CTypography'

export interface ICBenefitTile {
  icon: ReactNode
  title?: string
  description: string
  showDivider?: boolean
  showTradeMark?: boolean
  showAsterisk?: boolean
  responsiveValue?: number
  className?: string
  descriptionClass?: string
  iconClass?: string
  descriptionVariant?:
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
}

// Bypasses Typescript error: cannot be used as a JSX component
const Box: any = MuiBox

const useStyles = makeStyles(theme => ({
  icon: {
    width: '30%',
    height: '30%',
    marginBottom: 20,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    textAlign: 'center',
    fontSize: '1.5rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h1.fontSize,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  trademark: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize,
    fontFamily: theme.typography.h1.fontFamily,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.625rem',
    },
  },
  divider: {
    background: theme.palette.primary.main,
  },
}))

const CBenefitTile = (props: ICBenefitTile) => {
  const Icon: any = props.icon
  const classes = useStyles()

  return (
    <Grid item sm={props.responsiveValue || 4} display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center" className={props.className}>
      <Icon color="primary" className={props.iconClass || classes.icon} />

      { props.title && (
        <Box className={classes.titleContainer}>
          <CTitle noWrap className={classes.title}>{ props.title }</CTitle>
          { props.showTradeMark && <sup className={classes.trademark}>TM</sup> }
          { props.showAsterisk && <CTitle className={classes.title}>*</CTitle> }
        </Box>
      ) }

      { props.showDivider && (
        <CDivider size="30%" orientation="horizontal" marginTop={2} marginBottom={2} className={classes.divider} />
      ) }

      <CTypography variant={props.descriptionVariant || 'body1'} className={props.descriptionClass}>
        { props.description }
      </CTypography>
    </Grid>
  )
}

export default CBenefitTile
