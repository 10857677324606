import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'

import { Box, CircularProgress, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'

import useUser from 'user-module/user/core/useUser'
import InsidersClubBanner from 'spypoint/src/checkout-module/order/ui/cart-items/InsidersClubBanner'
import OrderSummary from 'spypoint/src/checkout-module/order/ui/checkout-page/OrderSummary'
import BillingAddress from 'spypoint/src/checkout-module/order/ui/checkout-page/billing/BillingAddress'
import StripeProvider from 'spypoint/src/checkout-module/order/ui/checkout-page/payment/StripeProvider'
import { getStripeElementOptions } from 'spypoint/src/checkout-module/order/ui/checkout-page/new-payment-methods/stripeHelper'
import CheckoutForm from 'spypoint/src/checkout-module/order/ui/checkout-page/new-payment-methods/CheckoutForm'
import PaymentInformation from 'spypoint/src/checkout-module/order/ui/checkout-page/payment/PaymentInformation'
import TermsAndConditionsLine from 'spypoint/src/checkout-module/order/ui/checkout-page/checkout-lines/TermsAndConditionsLine'
import CheckoutButtons from 'spypoint/src/checkout-module/order/ui/checkout-page/CheckoutButtons'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import { useLoadUserDefaultPaymentInfo } from 'spypoint/src/user-module/payment-methods/core/userPaymentMethods.hooks'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import { isExternalWebApp } from 'shared-module/webview/isExternalWebApp'
import cartApi from 'spypoint/src/checkout-module/order/core/cart.api'
import messageActions from 'shared-module/message/messageActions'
import CartPromoCode from '../cart-page/CartPromoCode'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'

const useStyles = makeStyles((theme) => ({
  rightColumn: {
    [theme.breakpoints.down('xs')]: {
      marginTop: -24,
    },
  },
  removeExtraPadding: {
    '& > div': {
      [theme.breakpoints.down('xs')]: {
        padding: '0',
      },
    },
  },
}))

const CheckoutComponent = ({ isActivation = false, setStep }) => {
  const theme = useTheme()
  const classes = useStyles()

  const user = useUser()
  const dispatch = useDispatch()
  const userClubMembership = useUserClubMembership()
  const paymentInfo = useLoadUserDefaultPaymentInfo()
  const twoColumns = useMediaQuery(theme.breakpoints.up('md'))
  const { cartItems, fetchCheckout, creditCartNotRequired, isInsidersClubInCart } = useCart()

  const newPaymentsEnabled = false

  const [stripeOptions, setStripeOptions] = useState()
  const [spinner, setSpinner] = useState(false)
  const [paymentIntentData, setPaymentIntentData] = useState(null)
  const [expanded, setExpanded] = useState(false)
  const [disabledPurchase, setDisabledPurchase] = useState(!user.isAddressComplete)
  const [checkoutIsExited, setCheckoutIsExited] = useState(false)
  const [paymentInfoState, setPaymentInfoState] = useState({
    isReady: !!paymentInfo?.isAvailable || creditCartNotRequired,
    isOpenForm: !paymentInfo?.isAvailable,
  })

  const isExternal = isExternalWebApp()
  const showBanner = !userClubMembership.isMember && !isInsidersClubInCart

  useEffect(() => {
    if (!paymentInfoState.isReady) {
      setPaymentInfoState({ isReady: !!paymentInfo?.isAvailable || creditCartNotRequired, isOpenForm: !paymentInfo?.isAvailable })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInfo])

  useEffect(() => {
    if (paymentIntentData && paymentIntentData.clientSecret) { setStripeOptions(getStripeElementOptions(theme, paymentIntentData)) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntentData])

  useEffect(() => {
    setDisabledPurchase(!user.isAddressComplete)
  }, [user])

  useEffect(() => {
    if (!newPaymentsEnabled && user.isAddressComplete) fetchCheckout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!newPaymentsEnabled || paymentIntentData) {
      return
    }

    // Call backend to get Payment Intent data
    const fetchClientSecret = async () => {
      setSpinner(true)

      try {
        // Generate initial preview
        await fetchCheckout()

        // Establish session with Stripe and obtain client_secret
        const checkoutData = await cartApi.triggerOrder()

        setSpinner(false)
        setPaymentIntentData({
          clientSecret: checkoutData.paymentIntentSecret,
          amount: checkoutData.amount,
          currency: checkoutData.currency,
        })
      } catch (error) {
        dispatch(messageActions.showError(error))
        setSpinner(false)
      }
    }

    fetchClientSecret()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (paymentIntentData && paymentIntentData.clientSecret !== null && !checkoutIsExited) {
      const fetchClientSecret = async () => {
        try {
          // Updates session with Stripe if cart information are chaging
          const checkoutData = await cartApi.triggerOrder()

          setPaymentIntentData({
            clientSecret: checkoutData.paymentIntentSecret,
            amount: checkoutData.amount,
            currency: checkoutData.currency,
          })
        } catch (error) {
          dispatch(messageActions.showError(error))
          setSpinner(false)
        }
      }

      fetchClientSecret()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems])

  return (
    <>
      <Grid item xs={12} sm={8} md={6} lg={4} xl={3}>
        { newPaymentsEnabled
          ? <OrderSummary products={cartItems.products} />
          : (
            <>
              { showBanner && !isExternal && !isActivation && (
                <>
                  <InsidersClubBanner />
                  <br />{ ' ' }
                </>
              ) }
              { twoColumns && (
                <>
                  <BillingAddress />
                  { newPaymentsEnabled && (
                    <FormDataContext>
                      <CartPromoCode expanded={expanded} setExpanded={setExpanded} />
                    </FormDataContext>
                  ) }
                </>
              ) }
            </>
            ) }
      </Grid>

      <Grid item xs={12} sm={8} md={6} lg={4} xl={3} className={newPaymentsEnabled ? classes.rightColumn : ''}>
        { newPaymentsEnabled
          ? (
            <>
              <BillingAddress />
              { newPaymentsEnabled && (
                <FormDataContext>
                  <CartPromoCode expanded={expanded} setExpanded={setExpanded} />
                </FormDataContext>
              ) }

              { !spinner && paymentIntentData && stripeOptions
                ? (
                  <StripeProvider id="newCheckout" options={stripeOptions}>
                    <CheckoutForm
                      disabled={disabledPurchase}
                      paymentInfo={paymentInfo}
                      paymentInfoState={paymentInfoState}
                      paymentIntentData={paymentIntentData}
                      setCheckoutIsExited={setCheckoutIsExited}
                      updatePaymentInfoState={(isReady, isOpenForm) => setPaymentInfoState({ isReady, isOpenForm })}
                    />
                  </StripeProvider>
                  )
                : (
                  <CircularProgress />
                  ) }
            </>
            )
          : (
            <>
              <OrderSummary products={cartItems.products} />
              { !twoColumns && (
                <>
                  <BillingAddress />
                  { newPaymentsEnabled && (
                    <FormDataContext>
                      <CartPromoCode expanded={expanded} setExpanded={setExpanded} />
                    </FormDataContext>
                  ) }
                </>
              ) }
              <StripeProvider id="oldCheckout">
                <PaymentInformation
                  paymentInfo={paymentInfo}
                  paymentInfoState={paymentInfoState}
                  updatePaymentInfoState={(isReady, isOpenForm) => setPaymentInfoState({ isReady, isOpenForm })}
                />
                <TermsAndConditionsLine />
                <Box className={classes.removeExtraPadding}>
                  <CheckoutButtons
                    setStep={setStep}
                    creditCartNotRequired={creditCartNotRequired}
                    user={user}
                    isActivation={isActivation}
                    disabledPlaceOrder={(!paymentInfoState.isReady && !creditCartNotRequired) || !user.isAddressComplete}
                    createPaymentInfo={paymentInfoState.isOpenForm}
                  />
                </Box>
              </StripeProvider>
            </>
            ) }
      </Grid>
    </>
  )
}

export default CheckoutComponent
